<!-- 设备类型kpi分析 -->
<template>
    <div class="page_container" v-loading="loading">
    <div class="box_head1  flex justify_start align_center">
      <span>设备类型KPI分析</span>
      <el-cascader size="medium" class="el_cascader_width mg_right_10 mg_left_50" ref="demoCascader" v-model="organizationValue" :options="organizationAr"
          placeholder="组织选择"
          :props="{multiple: true,checkStrictly:true,value:'ID',label:'Name',children:'children',expandTrigger: 'hover'}"
          @change="handleChange" :show-all-levels='false' collapse-tags clearable filterable>
      </el-cascader>
      <el-select v-model="eqtIDValue" clearable multiple collapse-tags placeholder="多选设备类型" class="mg_right_10 width_150"
        >
        <el-option v-for="item in kpiAnalysisEquipmentTypeSelectList" :key="item.index" :label="item.name"
          :value="item.value" class="">
        </el-option>
      </el-select>
      <el-select v-model="dtValue" placeholder="选择时间维度" class="mg_right_10 width_110" >
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-date-picker value-format="timestamp" v-model="value2" class="mg_right_10" type="datetimerange" align="right" start-placeholder="开始日期"
        end-placeholder="结束日期" :picker-options="pickerOptions">
      </el-date-picker>
      <el-button type="primary" @click="Querytest" size="medium">
      查询</el-button>
    </div>
    <div class="flex flex_column bgc_FFFFFF ">
      <!-- table -->
      <div class="flex flex_column alarmQueryTable alarmQueryMargin fn_size14">
        <el-table :data="typeAnalysisList" class="width_100" :row-style="{height:40+'px'}" :cell-style="cellStyle"
          height="440" border>
          <el-table-column type="index" label="序号" width="60"></el-table-column>
          <el-table-column prop="name" label="组织类型" min-width=" "></el-table-column>
          <el-table-column prop="equipmentCategoryName" label="设备类型" min-width=" "></el-table-column>
          <el-table-column prop="runStatus" label="运行数量" min-width=" "></el-table-column>
          <el-table-column prop="failStatus" label="故障数量" min-width=" "></el-table-column>
          <el-table-column prop="stopStatus" label="停止数量" min-width=" "></el-table-column>
          <el-table-column prop="PowerOnRate" label="开机率（%）" min-width=" "></el-table-column>
          <el-table-column prop="FaultRate" label="故障率（%）" min-width=" "></el-table-column>
          <el-table-column prop="TimeMobility" label="稼动率" min-width=" "></el-table-column>
          <el-table-column prop="MTTR" label="MTTR（h）" min-width=" "></el-table-column>
          <el-table-column prop="MTBF" label="MTBF（h）" min-width=" "></el-table-column>
          <el-table-column prop="updateTime" label="更新时间" min-width=" "></el-table-column>
        </el-table>
      </div>
      <div class="flex justify_between align_start mg_right_30 mg_left_30">
        <div> </div>
        <!-- <el-pagination :page-sizes="[5, 10, 100]" :page-size="pageSize" :total="workShopSelect.length"
                @current-change="handleCurrentChange" @size-change="handleSizeChange" layout="prev, pager, next">
              </el-pagination> -->
      </div>
      <div class="box_head1  flex justify_start align_center mg_top_20">
        <span>设备类型KPI分析趋势图</span>
        <el-select v-model="eqtKpiValue" @change="selectKpiChange" placeholder="设备类型Kpi指标"
          class="width_150  mg_left_30 ">
          <el-option v-for="item in kpiAnalysisEquipmentTypeKpiSelectList" :key="item.id" :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
      <!-- echarts图 -->
      <div class="diy_div alarmQueryMargin">
        <div id="chartColumn" class="width_100 height_100"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as echarts from "echarts";

  import {
    kpiAnalysisOrganizationSelect,
    kpiAnalysisEquipmentTypeAnalysis,
    kpiAnalysisEquipmentTypeAnalysisTrend,
    kpiAnalysisEquipmentTypeSelect,
    kpiAnalysisEquipmentTypeKpiSelect,

    kpiAnalysisEquipmentSelect,
    kpiAnalysisEquipmentAnalysis,
    kpiAnalysisEquipmentAnalysisTrend,
    kpiAnalysisEquipmentKpiSelect,

  } from "@/api/api_deviceManagement";

  import loginVue from '../../login.vue';

  export default {
    data() {
      return {
        value: "",
        value1: [new Date(new Date().toLocaleDateString()).getTime(), new Date().getTime()],
        value2: [new Date(new Date().toLocaleDateString()).getTime(), new Date().getTime()],
        value3: [new Date(new Date().toLocaleDateString()).getTime(), new Date().getTime()],
        activeName: "1",
        time: new Date(),
        date: "",
        visible: false,
        dialogTableVisible: false,
        collapseNames: "",
        options: [{
          value: '1',
          label: '日'
        }, {
          value: '2',
          label: '月'
        }],
        options1: [{
          value: '1',
          label: '日'
        }, {
          value: '2',
          label: '月'
        }],
        typeAnalysisList: [],
        analysisList: [],
        kpiAnalysisEquipmentTypeKpiSelectList: [],
        eqtKpiValue: 1,
        kpiSelectData: [],
        kpiAnalysisEquipmentTypeSelectList: [],
        eqKpiValue: 1,
        kpiAnalysisEquipmentKpiSelectList: [],
        permission: {},
        defaultProps: {
          label: 'Name',
          value: 'ID',
          expandTrigger: 'hover',
          multiple: true, //多选
          emitPath: false, //只返回选中节点的值
          // checkStrictly: true //多选任一级
        },
        currentData1: [],
        standardData: [],
        nameList: [],
        organizationValue: [],
        organizationValue1: [],
        organizationAr: [],
        organizationAr1: [],
        orgTreeData: [],
        orgTreeData1: [],
        standardArr: [],
        standardArr1: [],
        checkData: {},
        checkData1: {},
        tkValue: "1",
        tkValue1: "1",
        eqtIDValue: [],
        dtValue: "1",
        eqIDValue: [],
        dtValue1: "1",
        nowID: "",
        nowID1: "",
        organization: {},
        loading: true,
        equipmentArr: [],
        queryData:[]
      };
    },
    mounted() {
      if (localStorage.getItem('permission') != null) {
        // console.log(JSON.parse(localStorage.getItem('permission')));
        this.permission = JSON.parse(localStorage.getItem('permission'))
      }
      if (localStorage.getItem('userInfo') != null) {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        this.userID = userInfo.userID
      }
      // this.kpiAnalysisEquipmentTypeAnalysis();
      // this.kpiAnalysisEquipmentTypeAnalysisTrend();
      // this.kpiAnalysisEquipmentAnalysis();
      // this.kpiAnalysisEquipmentAnalysisTrend();

      this.kpiAnalysisOrganizationSelect();
      this.kpiAnalysisEquipmentTypeSelect();
      this.kpiAnalysisEquipmentTypeKpiSelect();
      // this.kpiAnalysisEquipmentSelect();
      // this.kpiAnalysisEquipmentKpiSelect();
    },
    methods: {
      //loading 
      getData() {
        const loading = this.$loading({
          lock: true, //lock的修改符--默认是false
          text: 'Loading', //显示在加载图标下方的加载文案
          spinner: 'el-icon-loading', //自定义加载图标类名
          background: 'rgba(0, 0, 0, 0.7)', //遮罩层颜色
          target: document.querySelector('#table') //loadin覆盖的dom元素节点
        });
        loading.close() //成功回调函数停止加载
      },

      Querytest() {
        this.kpiAnalysisEquipmentTypeAnalysis();
        this.kpiAnalysisEquipmentTypeAnalysisTrend();
      },

      //设备类型
      async kpiAnalysisEquipmentTypeAnalysis() {

        this.checkData.CategoryID = this.eqtIDValue
        // this.checkData.CategoryID = [1]
        var param = {
          organization: this.checkData,
          // startDate: this.value2[0],
          // endDate: this.value2[1],
          dateType: this.dtValue,
          permission: this.permission
        };
        if (this.value2 != null) {
          param.startDate = this.value2[0]
          param.endDate = this.value2[1]
        }
        const selectRes = await kpiAnalysisEquipmentTypeAnalysis(param);
        this.typeAnalysisList = selectRes.data
      },
      async kpiAnalysisEquipmentTypeAnalysisTrend() {
        echarts.init(document.getElementById("chartColumn")).dispose(); //销毁echarts
        this.checkData.CategoryID = this.eqtIDValue
        var param = {
          organization: this.checkData,
          // startDate: this.value2[0],
          // endDate: this.value2[1],
          dateType: this.dtValue,
          permission: this.permission,
          typeKpi: this.eqtKpiValue
        };
        if (this.value2 != null) {
          param.startDate = this.value2[0]
          param.endDate = this.value2[1]
        }
        const selectRes = await kpiAnalysisEquipmentTypeAnalysisTrend(param);
        var queryData = selectRes.data;
        var line = {
          legend: {
            type: "scroll",
            data: [],
            bottom: '2%',

            textStyle: {
              color: '#808080',
              fontSize: 12,
              rotate: 0,
            },

          },
          xAxis: {
            type: "category",
            boundaryGap: true,
            axisTick: {
              alignWithLabel: true, //x轴左右留白
            },
            axisLine: {
              lineStyle: {
                color: "#E3E4E6",
              },
            },
            axisLabel: {
              interval: 0,
              color: "#808080",
              fontSize: 12,
              rotate: 60,
            },
            data: [],
          },
          series: []
        };
        this.chartColumn = echarts.init(document.getElementById('chartColumn'));
        this.chartColumn.showLoading({
          text: '加载中，请稍候...',
            // maskColor: 'rgba(3,3,8,0.5)',
            // textColor: '#fff600'
        });
        if (queryData.length > 0) {
          line.xAxis.data = queryData[0].time;
          for (var i = 0; i < queryData.length; i++) {
            var curObj = {
              type: 'line',
              symbol: "circle",
              symbolSize: 8, //拐点圆的大小
            };
            curObj.name = queryData[i].name
            if (this.eqtKpiValue == "1") {
              curObj.data = queryData[i].OperationRateList
            } else if (this.eqtKpiValue == "2") {
              curObj.data = queryData[i].TimeMobilityList
            } else if (this.eqtKpiValue == "3") {
              curObj.data = queryData[i].FaultRateList
            }
            line.series[i] = curObj
            line.legend.data[i] = queryData[i].name
          }
        }
        console.log(line);
        this.drawShape(line);

      },
      //图
      drawShape(line) {
        echarts.init(document.getElementById("chartColumn")).dispose(); //销毁echarts
        /**数据集折线图 */
        this.chartColumn = echarts.init(document.getElementById("chartColumn"));
        this.chartColumn.setOption({
          title: {},
          // 提示框
           tooltip: {
              trigger: 'axis',
              textStyle:{
                  align:'left'
              }
          },
          color: ['#6395F9', '#62DAAB', '#657798', '#F6C022'],
          legend: line.legend,
          grid: {
            //设置中间内容距离div的距离，也就是内边距
            left: "3%",
            right: "4%",
            bottom: "10%",
            containLabel: true,
            /* width: 'auto', height: 'auto', */
          },
          xAxis: line.xAxis,

          yAxis: {
            type: 'value',
            // min: 0, //y轴的最小值
            // max: 300, //y轴最大值
            // interval: 30, //值之间的间隔
          },
          series: line.series,
        });
        this.chartColumn.hideLoading()
      },


      cellStyle(row, column, rowIndex, columnIndex) {
        /* 设备状态 */
        if (
          row.column.label === "运行数量"
        ) {
          return "color:#56DC28";
        } else if (
          row.column.label === "故障数量"
        ) {
          return "color:#FEBC00";
        } else if (
          row.column.label === "停止数量" 
        ) {
          return "color:#ff5353";
        } else if (
          row.column.label === "设备状态" &&
          row.row.equipmentStatus === "故障"
        ) {
          return "background:#EE2C3B";
        }
        /* 开机率 */
        if (row.column.label === "开机率（%）" && row.row.powerOnRate > 80) {
          return "background:#56DC28";
        } else if (
          row.column.label === "开机率（%）" &&
          row.row.powerOnRate > 70 &&
          row.row.powerOnRate <= 80
        ) {
          return "background:#FEFC55";
        } else if (
          row.column.label === "开机率（%）" &&
          row.row.powerOnRate < 70
        ) {
          return "background:#EE2C3B";
        }
        /* 开机率 */
        if (row.column.label === "移动率（%）" && row.row.movementRate > 80) {
          return "background:#56DC28";
        } else if (
          row.column.label === "移动率（%）" &&
          row.row.movementRate > 70 &&
          row.row.movementRate <= 80
        ) {
          return "background:#FEFC55";
        } else if (
          row.column.label === "移动率（%）" &&
          row.row.movementRate < 70
        ) {
          return "background:#EE2C3B";
        }
        /* 开机率 */
        if (row.column.label === "故障率（%）" && row.row.failtRate > 10) {
          return "background:#56DC28";
        } else if (
          row.column.label === "故障率（%）" &&
          row.row.failtRate >= 5 &&
          row.row.failtRate < 10
        ) {
          return "background:#FEFC55";
        } else if (row.column.label === "故障率（%）" && row.row.failtRate < 5) {
          return "background:#B8FB7C";
        }
      },

      //组织选择变动
      handleChange() {
        if (this.organizationValue.length == 0) {
          this.checkData = this.standardArr[0];
          return
        }
        const obj = this.$refs['demoCascader'].getCheckedNodes()
        var currentData = {
          Type: [],
          ID: []
        };
        for (var i = 0; i < obj.length; i++) {
          var authorityadmin = this.standardArr.filter(itmer => itmer.Type == obj[i].data.Type);
          if (authorityadmin.length == 0) {
            this.$message({
              message: '警告哦，您没有权限选择此项',
              type: 'warning'
            });
            this.organizationValue = []; //清空选项
            return
          }
          currentData.Type.push(authorityadmin[0].Type);
          var idList = authorityadmin[0].ID;
          for (var j = 0; j < idList.length; j++) {
            var authorityadminTwo = idList.filter(itmer => itmer == obj[i].data.ID);
            if (authorityadminTwo.length == 0) {
              this.$message({
                message: '警告哦，您没有权限选择此项',
                type: 'warning'
              });
              this.organizationValue = [];
              return
            } else {
              for (var z = 0; z < currentData.Type.length; z++) {
                if (authorityadmin[0].Type != currentData.Type[z]) { //只能选择同一级的选项
                  this.$message({
                    message: '警告哦，请相同级别选项',
                    type: 'warning'
                  });
                  this.organizationValue = [];
                  return
                }
              }
              currentData.ID.push(authorityadminTwo[0])
              break
            }
          }
          // }
        }

        var testData = {
          Type: '',
          ID: []
        }
        testData.Type = currentData.Type[0];
        testData.ID = currentData.ID;
        if (this.organizationValue.length) {
          this.checkData = this.standardArr[0]
        }
        this.checkData = testData;
        console.log(this.checkData);

      },


      //组织选择
      async kpiAnalysisOrganizationSelect() {
        var param = {
          permission: this.permission
        };
        const selectRes = await kpiAnalysisOrganizationSelect(param);
        this.organizationAr = selectRes.data.Tree;
        this.standardArr = selectRes.data.organizationPermissionAll;
        this.checkData = selectRes.data.organizationPermissionAll[0];
        // this.organizationValue = selectRes.data.organizationPermissionAll[0].ID;
        this.kpiAnalysisEquipmentTypeSelect()
        // this.kpiAnalysisEquipmentTypeKpiSelect()
        this.kpiAnalysisEquipmentTypeAnalysis()
        this.kpiAnalysisEquipmentTypeAnalysisTrend()
        this.loading = false;
      },

      //设备Type选择
      async kpiAnalysisEquipmentTypeSelect() {
        var param = {
          organization: this.checkData
        };
        const selectRes = await kpiAnalysisEquipmentTypeSelect(param);
        this.kpiAnalysisEquipmentTypeSelectList = selectRes.data

      },

      //设备Type KPI指标选择框
      async kpiAnalysisEquipmentTypeKpiSelect() {
        var param = {
          organization: {}
        };
        const selectRes = await kpiAnalysisEquipmentTypeKpiSelect(param);
        this.kpiAnalysisEquipmentTypeKpiSelectList = selectRes.data.Kpi
      },
      //设备Type kpi指标变动反馈
      selectKpiChange() {
        // this.kpiAnalysisEquipmentTypeAnalysis()
        this.kpiAnalysisEquipmentTypeAnalysisTrend();
      },
    },
  };
</script>

<style>
  /*修改popper-class格式 popper-class .el-popover.my-popover 这种格式，前缀是.el-popover 不然不生效。且不能加上scoped */
  .el-popover.my-popover {
    top: 425px !important;
  }
</style>

<style scoped>
  .alarmQuerySelect {
    width: 150px;
    height: 36px;
  }

  .alarmQuerySelectButton {
    width: 50px;
    height: 36px;
    background: #009bfd;
  }

  .alarmQueryTable {
    height: 441px;
    margin-right: 31px;
  }

  .alarmQueryMargin {
    margin-left: 31px;
    margin-top: 19px;
  }

  .width_110{
    width: 110px;
  }
  .diy_div {
    /*  width: 920px; */
    height: 400px;
  }

  .divMargin1 {
    margin: 16px 18px 0px 18px;
  }

  .divMargin2 {
    margin: 16px 18px 10px;
  }

  .diy_col {
    height: 63vh;
    margin-bottom: 14px;
  }

  .diy_col2 {
    height: 61vh;
    margin-bottom: 14px;
  }

  .diy_col1 {
    height: 40vh;
    margin-bottom: 14px;
  }

  .bg-purple {
    background: #e2f0fe;
    border: 1px solid #ccdbe5;
    height: 32px;
    line-height: 32px;
    display: flex;
    /* align-items: center; */
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    padding-left: 20px;
  }

  .height_vh {
    height: 280px;
  }

  .height_vh1 {
    height: 280px;
  }

  .height_vh2 {
    height: 345px;
  }

  .el-row {
    margin-right: 0px !important;
  }

  .height_380 {
    height: 380px;
  }

  .clickButton {
    height: 32px;
    width: 70px;
    color: #ffffff;
    background-color: #009bfd;
  }

  .downUp {
    height: 32px;
  }

  .transition-dom {
    transition: all 0.2s linear 0s;
    margin: 0px;
  }
  
</style>

<style lang="less" scoped>
  /* // ::v-deep .el-input {
  //   // width: 146px;
  // } */



  /* ::v-deep .el-input--mini .el-input__inner {
        height: 36px;
        line-height: 36px;
    } */

  ::v-deep .el-tabs__item {
    width: 80px;
    /* color: #999999; */
    padding: 0;
    font-size: 16px;
    height: 54px;
    line-height: 54px;
  }

  ::v-deep .el-tabs__item.is-active {
    color: #0099ff;
    font-weight: 500;
  }

  ::v-deep .el-badge__content.is-fixed {
    top: 15px;
    right: 6px;
  }
</style>